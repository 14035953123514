import React from 'react';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';

import imgCostAndEnv from '../../assets/img/home/icon_cost-and-environment_v2.svg';
import imgInfoConsult from '../../assets/img/home/icon_information_consultants_v1.svg';
import imgRenovation from '../../assets/img/home/icon_renovation-modernisation_v2.3.svg';
import imgFreeAndNeutral from '../../assets/img/home/icon_free-and-neutral_v2.svg';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import house_example from "../../assets/img/home/house_example.jpg";

import Partners from "../home/Partners";
import HowTo from "../home/HowTo";

export default function Home() {
    return <div>
        <Container>

            <Row className={'mt-5'}>
                <Col>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <h3>Das bieten wir:</h3>
                    </div>

                    <Carousel variant={"dark"} style={{height: '200px'}}>
                        <Carousel.Item>
                            <Image width={'100%'}
                                   height={120} src={imgCostAndEnv}></Image>
                            <Carousel.Caption style={{'position': 'relative', 'right': '50%', 'left': 0}}>
                                Kosten- und Umwelt-Analyse zu deinem Haus
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image width={'100%'}
                                   height={120}
                                   src={imgRenovation}></Image>
                            <Carousel.Caption style={{'position': 'relative', 'right': '50%', 'left': 0}}>
                                Vorschläge zu Renovation und Modernisierung
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image width={'100%'}
                                   height={120}
                                   src={imgInfoConsult}></Image>
                            <Carousel.Caption style={{'position': 'relative', 'right': '50%', 'left': 0}}>
                                Infos zu Fachberatern in deiner Umgebung
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image width={'100%'}
                                   height={120}
                                   src={imgFreeAndNeutral}></Image>
                            <Carousel.Caption style={{'position': 'relative', 'right': '50%', 'left': 0}}>
                                Kostenlos, neutral und ohne Werbung
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>

                    <div className={'d-flex justify-content-center mt-5 mb-5'}>
                    <Link className={'nav-link'} to="/auswahl">
                        <Button variant={"dark"} size={'lg'}>Jetzt mit Gebäudeauswahl starten</Button>
                    </Link>
                    </div>

                </Col>
                <Col xs={"auto"} style={{height: '450px'}} className={'justify-content-end d-flex'}>
                    <Image height={'100%'} src={house_example} />

                    {/*<Carousel variant={"dark"} style={{height: '350px'}} >*/}
                    {/*    <Carousel.Item>*/}
                    {/*    </Carousel.Item>*/}
                    {/*    <Carousel.Item>*/}
                    {/*        <Image height={300} src={situation_example} />*/}
                    {/*</Carousel.Item>*/}
                    {/*</Carousel>*/}
                </Col>

                {/*<Col xs={12} className={'mt-3'}>*/}
                {/*    <h4>Mithilfe dieser Daten kannst du das Tool optimal nutzen</h4>*/}
                {/*    <ul>*/}
                {/*        <li>Wohnfläche(n), Anzahl Bewohner</li>*/}
                {/*        <li>Strom- und Heizkostenabrechnung</li>*/}
                {/*        <li>Steuersatz (für die Finanzoptimierung)</li>*/}
                {/*        <li>Erfolgte Renovationen und Neuanschaffungen</li>*/}
                {/*        <li>Grösse/Leistung einer allfälligen Solaranlage</li>*/}
                {/*    </ul>*/}
                {/*</Col>*/}
            </Row>

            <HowTo />

            <Partners />

        </Container>
    </div>;
}
