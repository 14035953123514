import React from "react";
import {InputContainer} from "../layout/InputContainer";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {NumberDisplay} from "../measures/display/NumberDisplay";

export const EnergyAnalysis = ({
                                   item,
                                   colorIndex = {current: 3, target: 0},
                                   title,
                                   unit = "CHF",
                                   attribute,
                                   icon,
                                   showProposed = false,
                                   recalculateFirstTotal = false
                               }) => {
    if (!item) return "";

    const getValueArrayForAttribute = (item, attribute, valueType = 'actual') => {
        return item.energyComparison.map(i => {
            return {
                name: i['displayText'],
                value: Number(i[valueType][attribute][0])
            };
        });
    };

    const getParsedValues = (valueType) => {
        const parsed = {
            heating: 0,
            heatingPct: 0,
            hotWater: 0,
            hotWaterPct: 0,
            housePower: 0,
            housePowerPct: 0,
            total: 0
        };

        const actualValues = getValueArrayForAttribute(item, attribute, valueType);
        if (recalculateFirstTotal) {
            actualValues[0].value = actualValues.reduce((acc, curr) => acc + curr.value, 0);
            // proposed[0].value = proposed.reduce((acc, curr) => acc + curr.value, 0);
        }

        parsed.heating = actualValues.find(val => val.name === "Heizung").value;
        parsed.hotWater = actualValues.find(val => val.name === "Warmwasser").value;
        parsed.housePower = actualValues.find(val => val.name.startsWith("Haushaltstrom")).value;
        parsed.total = actualValues.find(val => val.name === "Dein Haus").value;

        parsed.heatingPct = parsed.heating / parsed.total;
        parsed.hotWaterPct = parsed.hotWater / parsed.total;
        parsed.powerPct = parsed.housePower / parsed.total;

        return parsed;
    };

    const actualValues = getParsedValues('actual');
    let proposedValues = undefined;

    if (showProposed) {
        proposedValues = getParsedValues('proposed');
        proposedValues.heatingPct = proposedValues.heating / actualValues.total;
        proposedValues.hotWater = proposedValues.hotWater / actualValues.total;
        proposedValues.housePower = proposedValues.housePower / actualValues.total;
    }


    const color = item.colorSet[colorIndex.current];
    const targetColor = item.colorSet[colorIndex.target];

    const printRow = (values, valueKey, pctKey, rowColor, small = false) => {
        if (!values) return "";
        return <>
            <Col xs={12} className={'energyGroup'}>
                <div className={'energyBarWrapper'}>
                    <span className={'energyBar'}
                          style={{
                              backgroundColor: rowColor,
                              width: `${values[pctKey] * 100}%`,
                              height: small ? '8px' : '1rem'
                          }}/>
                </div>
                <span className={'energyLabel'}>
                    <NumberDisplay fractionDigits={0}>{values[valueKey]}</NumberDisplay>
                    {' '}{unit}
                </span>
            </Col>
        </>;
    };

    return <>
        <Col xs={12} lg={4} style={{'height': '400px'}}>
            <InputContainer classes={'h-75 bg-opacity-10 ps-4'} color={'bg-secondary'}>
                <Row className={`my-3`}>
                    <Col xs={'auto'}>{icon}</Col>
                    <Col>
                        <strong>{title}</strong><br/>
                        <div><NumberDisplay fractionDigits={0}>{actualValues.total}</NumberDisplay> {unit}</div>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col xs={12}>
                        <strong>Heizung</strong>
                    </Col>
                    {printRow(actualValues, 'heating', 'heatingPct', color)}
                    {printRow(proposedValues, 'heating', 'heatingPct', targetColor, true)}

                </Row>
                <Row className={'mb-3'}>
                    <Col xs={12}>
                        <strong>Warmwasser</strong>
                        {/*<NumberDisplay fractionDigits={4}>{hotWaterPct}</NumberDisplay>*/}
                    </Col>
                    {printRow(actualValues, 'hotWater', 'hotWaterPct', color)}
                    {printRow(proposedValues, 'hotWater', 'hotWaterPct', targetColor, true)}

                </Row>
                <Row className={'mb-3'}>
                    <Col xs={12}>
                        <strong>Haushaltstrom</strong>
                        {/*<NumberDisplay fractionDigits={4}>{powerPct}</NumberDisplay>*/}
                    </Col>
                    {printRow(actualValues, 'housePower', 'powerPct', color)}
                    {printRow(proposedValues, 'housePower', 'powerPct', targetColor, true)}
                </Row>
            </InputContainer>
        </Col>
    </>;
};