import {createAction} from "@reduxjs/toolkit";

export const dataLoadFirstQuery = createAction('data/load1');
export const dataLoadFirstQuerySuccess = createAction('data/load1/success');
export const dataLoadFirstQueryFailure = createAction('data/load1/failure');

export const dataLoadSecondQuery = createAction('data/load2');
export const dataLoadSecondQuerySuccess = createAction('data/load2/success');
export const dataLoadSecondQueryFailure = createAction('data/load2/failure');

export const dataLoadThirdQuery = createAction('data/load3');
export const dataLoadThirdQuerySuccess = createAction('data/load3/success');
export const dataLoadThirdQueryFailure = createAction('data/load3/failure');

export const dataLoadFourthQuery = createAction('data/load4');
export const dataLoadFourthQuerySuccess = createAction('data/load4/success');
export const dataLoadFourthQueryFailure = createAction('data/load4/failure');

export const dataLoadFifthQuery = createAction('data/load5');
export const dataLoadFifthQuerySuccess = createAction('data/load5/success');
export const dataLoadFifthQueryFailure = createAction('data/load5/failure');

export const dataUpdateValue = createAction('data/update');
export const dataEnableParameter = createAction('data/enable');
export const dataEnableMeasureGroup = createAction('data/measures/enable');
export const dataMeasureSelectOption = createAction('data/measures/selectOption');
export const dataMeasureChangeYear = createAction('data/measures/changeYear');
export const dataUpdateTuneTargetValue = createAction('data/tuneTarget/setValue');
export const dataMeasureOptionUpdateSelection = createAction('data/measures/updateOptionSelection');