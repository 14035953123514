import React from 'react';
import {useSelector} from "react-redux";
import {selectDataActiveMeasures} from "../../reducers/dataSelectors";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CurrencyDisplay} from "./display/CurrencyDisplay";
import {NumberDisplay} from "./display/NumberDisplay";
import {ActiveMeasureItem} from "./ActiveMeasureItem";
import {Tooltip} from "react-tooltip";

export const ActiveMeasureList = () => {

    const activeMeasures = useSelector(selectDataActiveMeasures);
    if (!activeMeasures) return '';

    const printHeaderRow = () => {
        return <Row className={'small fw-bold border-bottom border-2 mb-3 pb-1'}
                    style={{'fontSize': '12px'}}>
            <Col xs={6} md={4} className={'pe-0'}>Massnahme</Col>
            <Col xs={6} md={4}>Variante</Col>
            <Col xs={{span: 3, offset: 6}} md={{span: 2, offset: 0}}  lg={3} xl={2} className={'d-flex justify-content-end px-0'}>Brutto <br />Investment</Col>
            <Col xs={3} md={2} className={'d-flex justify-content-end d-lg-none d-xl-flex px-0'}>
                CO2 Einsparung<br/>30 Jahre
            </Col>
        </Row>;
    };

    const printTotalRow = () => {
        return <Row className={'fw-bold border-top pt-3 mt-3 border-2'}
        >
            <Col xs={6} md={4}>Total</Col>
            <Col xs={6} md={4}></Col>

            <Col xs={{span: 3, offset: 6}} md={{span: 2, offset: 0}} lg={3} xl={2}  className={'d-flex justify-content-end px-0'}>
                <CurrencyDisplay showZero={true}>
                    {activeMeasures.reduce((acc, curr) =>
                        acc + (
                            Number(curr.subMeasure[0]
                                .subOption.find(x => x.uid === curr.subMeasure[0].value)
                                .cost.grossInvestment)
                        ), 0)
                    }
                </CurrencyDisplay>
            </Col>
            <Col  xs={3} md={2} className={'d-flex justify-content-end d-lg-none d-xl-flex px-0'}>
                <NumberDisplay showZero={true}  suffix={'kg'}>
                    {activeMeasures.reduce((acc, curr) =>
                        acc + (
                            Number(curr.subMeasure[0]
                                .subOption.find(x => x.uid === curr.subMeasure[0].value)
                                .cost.CO2Savings)
                        ), 0)
                    }
                </NumberDisplay>
            </Col>
        </Row>
    }

    let content = '';
    if (activeMeasures.length === 0) {
        content = <div><i>keine Massnahmen ausgewählt</i></div>;
    } else {
        content = <div>
            <Tooltip id={`measure-tooltip`} place={'right'} disableStyleInjection={false} />
            {printHeaderRow()}
            {activeMeasures.map(itm => <ActiveMeasureItem key={itm.parameter} item={itm} />)}
            {printTotalRow()}
        </div>;
    }

    return <div>
        {content}
    </div>;
};