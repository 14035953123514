import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDataEnergyAnalysis,
    selectDataLoading,
    selectDataSelectedAddress
} from "../../reducers/dataSelectors";
import {dataLoadFourthQuery} from "../../reducers/dataActions";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {logMessage} from "../../lib/logging";

import "./SituationAnalysis.css";
import {TbLeaf, TbPigMoney, TbWorld} from "react-icons/tb";
import {EnergyEfficiency} from "../energy-analysis/EnergyEfficiency";
import {EnergyAnalysis} from "../energy-analysis/EnergyAnalysis";
import {settingsSetClientEvent, settingsSetEventUID, settingsSetMeasureLoading} from "../../reducers/settingsActions";

export const SituationAnalysis = (props) => {
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const energyAnalysis = useSelector(selectDataEnergyAnalysis);

    const loading = useSelector(selectDataLoading);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedAddress === undefined && !loading.first) {
            logMessage('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [selectedAddress, loading, navigate]);

    const handleMeasureClick = () => {
        dispatch(settingsSetEventUID({eventUID: undefined}))
        dispatch(settingsSetClientEvent({clientEvent: 'optimize'}))
        dispatch(settingsSetMeasureLoading({value: 0}))
        logMessage('loading forth query');
        dispatch(dataLoadFourthQuery());
        logMessage('redirect to measures');
        navigate('/massnahmen');
    };

    return <>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>Situationsanalyse</PageTitle>
            <Row>
                <Col xs={12} style={{'height': '200px'}}>
                    <EnergyEfficiency/>
                </Col>
            </Row>
            <Row>
                <EnergyAnalysis
                    title={'Energiekosten'}
                    unit={'CHF'}
                    attribute={'energyCost'}
                    icon={<TbPigMoney size={40}/>}
                    item={energyAnalysis}
                    colorIndex={{current: 3, target: 0}}
                    showProposed={false}
                />
                <EnergyAnalysis
                    title={<>CO<sub>2</sub>-Ausstoss</>}
                    unit={'kg'}
                    attribute={'CO2emission'}
                    icon={<TbWorld size={40}/>}
                    item={energyAnalysis}
                    colorIndex={{current: 2, target: 0}}
                    showProposed={false}
                />
                <EnergyAnalysis
                    title={'Energieverbrauch'}
                    unit={'kWh'}
                    attribute={'usedEnergy'}
                    icon={<TbLeaf size={40}/>}
                    item={energyAnalysis}
                    recalculateFirstTotal={true}
                    colorIndex={{current: 1, target: 0}}
                    showProposed={false}
                />
            </Row>

            <Row className={'pb-5'}>
                <Col className={'d-flex justify-content-end'}>
                    <Link className={'nav-link'} to="/dateneingabe">
                        <Button size={'sm'} style={{'width': '200px'}}>zurück</Button>
                    </Link>
                    <Button variant={"dark"} style={{'width': '200px'}}
                            className={'ms-3'} size={'sm'}
                            onClick={handleMeasureClick}>Massnahmen</Button>
                </Col>
            </Row>
        </Container>
    </>;
};