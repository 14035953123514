import React, {useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {MeasureOptions} from "./MeasureOptions";
import {CurrencyDisplay} from "./display/CurrencyDisplay";
import {NumberDisplay} from "./display/NumberDisplay";
import {MeasureStar} from "./MeasureStar";
import {MdOutlineInfo} from "react-icons/md";

import "./ActiveMeasureItem.css";
import {RxCross2, RxCrossCircled} from "react-icons/rx";
import {useDispatch, useSelector} from "react-redux";
import {dataLoadFifthQuery, dataMeasureOptionUpdateSelection} from "../../reducers/dataActions";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const ActiveMeasureItem = ({item}) => {

    const expertModeEnabled = useSelector(state => state.settings.measureExpertView);
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    if (!item) return "";

    const measureName = item.displayText;
    const selectedMeasureOption = item.subMeasure[0].subOption.find(x => x.uid === item.subMeasure[0].value);

    const optionSelectionClicked = (option, value) => {
        console.log(item.parameter, option, value);
        dispatch(settingsSetEventUID({eventUID: option}));
        dispatch(settingsSetClientEvent({clientEvent: 'editOption'}));
        dispatch(dataMeasureOptionUpdateSelection({
            parameter: item.parameter,
            option: option,
            selection: value
        }));
        dispatch(dataLoadFifthQuery());
    };

    const getMainRow = () => {
        return <Row key={item.parameter} className={'mt-2'}>
            <Col xs={6} md={4} className={'pe-0'}>
                <MeasureStar item={item}/>
                {measureName}


                <MdOutlineInfo className={'ms-2'}
                               data-tooltip-id={`measure-tooltip`}
                               data-tooltip-content={measureName}/>

            </Col>
            <Col xs={6} md={4} lg={5} xl={4}>
                <Row>
                    <Col><MeasureOptions item={item.subMeasure[0]}/></Col>
                    {getExpertModeExpandPreference()}
                </Row>
                {/*{selectedMeasureOption.displayText}*/}
            </Col>
            <Col xs={{span: 3, offset: 6}} md={{span: 2, offset: 0}} lg={3} xl={2}
                 className={'d-flex justify-content-end px-0'}>
                <CurrencyDisplay>
                    {Number(selectedMeasureOption.cost.grossInvestment)}
                </CurrencyDisplay>
            </Col>
            <Col xs={3} md={2} className={'d-flex justify-content-end d-lg-none d-xl-flex px-0'}>
                <NumberDisplay showZero={true} suffix={'kg'}>
                    {Number(selectedMeasureOption.cost.CO2Savings)}
                </NumberDisplay>
            </Col>
        </Row>;
    };

    const getEditOpenPreferencesRow = () => {
        if (!isOpen) return "";
        if (!expertModeEnabled) return "";

        return <Row>
            <Col xs={6} md={4}/>
            <Col xs={6} md={4} lg={5} xl={4} className={'small'}>
                {item.subMeasure[0].subOption.map(x => <Row key={x.uid}>
                    <Col>
                        <span className={'cursor-pointer'}>
                        {x.selection && x.selection === "-1" ?
                            <RxCrossCircled onClick={() => optionSelectionClicked(x.uid, '0')} className={'text-danger'}
                                            size={15}/> :
                            <RxCross2 onClick={() => optionSelectionClicked(x.uid, '-1')} size={15}/>}
                            </span>
                        <span className={'ms-2'}>{x.displayText}</span>
                        {x.selection ? <span className={'text-info ms-2'}>({x.selection})</span> : ''}</Col>
                </Row>)}
            </Col>
            <Col xs={{span: 3, offset: 6}} md={{span: 2, offset: 0}} lg={3} xl={2}/>
            <Col xs={3} md={2} className={'d-flex justify-content-end d-lg-none d-xl-flex'}/>
        </Row>;
    };

    const getExpertModeExpandPreference = () => {
        if (!expertModeEnabled) return "";
        return <Col xs={'auto'} className={'px-0'}>
            <div onClick={() => {
                setOpen(!isOpen);
            }} className={`myOpenArrow ${isOpen ? '' : 'collapsed'}`}
                 style={{'marginTop': '4px'}}></div>
        </Col>;
    };

    return <>
        {getMainRow()}
        {getEditOpenPreferencesRow()}
    </>;
};